<template>
    <div class="information">
        <div class="content_box_">
            <h1 class="title_">您的信息</h1>
            <div class="content_name_text_box_">
                <p class="name_ span_style__1"><span class="text__">姓</span>名：{{info_data.name}}</p>
                <p class="xex_ span_style__1"><span class="text__">性</span>别：{{info_data.sex}}</p>
                <p class="date_of_birth_"><span class="text__">出生日期</span>：</p>
                <p class="Gregorian_calendar_ span_style__1"><span class="text__">公</span>历：{{`${lunar_calendar_value.cYear}年${lunar_calendar_value.cMonth}月${lunar_calendar_value.cDay}日${lunar_calendar_value.tiem_}时`}}</p>
                <p class="lunar_calendar_ span_style__1"><span class="text__">阴</span>历：{{`(${lunar_calendar_value.yearCn_})${lunar_calendar_value.yearCn}${lunar_calendar_value.monthCn}${lunar_calendar_value.dayCn}${lunar_calendar_value.tiemCn_}`}}</p>
            </div>
        </div>
        <div class="content_box_">
            <h1 class="title_">八字排盘</h1>
            <div class="horoscope_box">
                    <div class="eight_layout">
                        
                        <Paipan
                            :sex="orderinfo.sex"
                            :data="orderinfo.eightChara"
                            style="width:calc(100% - 30px);padding-left:15px"
                        />
                         <h2 class="text">五行分析喜用字</h2>
                        <div class="tit1 ml-15">
                            五行个数
                        </div>
                        <div class="txt ml-15">
                            金{{ orderinfo.askTao.ori_qk_score["金"] }} 木{{
                            orderinfo.askTao.ori_qk_score["木"]
                            }}
                            水{{ orderinfo.askTao.ori_qk_score["水"] }} 火{{
                            orderinfo.askTao.ori_qk_score["火"]
                            }}
                            土{{ orderinfo.askTao.ori_qk_score["土"] }}
                        </div>
                        <div class="txt ml-15">
                            个人运势不是按照五行缺什么就补什么，<span class="color-red"
                            >应以先天八字中五行同类和异类互为平衡原则，补充最需要的五行作为喜用。</span
                            >
                        </div>
                        <div class="txt">
                            您的日主天干属<span class="color-red">{{
                            orderinfo.askTao.benming
                            }}</span
                            >，经计算，您的八字同类五行是
                            <span
                            v-for="(value, key, index) in orderinfo.askTao.ww_score_ratio"
                            :key="key"
                            class="color-red"
                            >{{ key }}<span v-if="index == 0">、</span></span
                            >
                            。
                        </div>
                        <div class="center">
                            <img
                            style="width:70%"
                            src="//cdn.names.org.cn/website/Content/szname/img/WxGif2.gif"
                            />
                        </div>
                        <WuxingCate
                            :data="orderinfo.askTao"
                            :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                            style="padding:0 15px;margin:15px 0"
                        />
                        <div class="tit1 ml-15">
                            五行含量
                        </div>
                        <div class="center wchl">
                            <!-- 五行含量组件 -->
                            <WuxingHl
                            :score="orderinfo.askTao.qk_score"
                            :qk_score_ratio="orderinfo.askTao.qk_score_ratio"
                            />
                        </div>
                        <!-- 五行喜用分析开始 -->
                        <div class="tit1 ml-15">
                            五行喜用分析
                        </div>
                        <div class="txt ml-15">
                            同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
                        </div>
                        <div class="txt ml-15">
                            异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
                        </div>
                        <div class="txt ml-15">综合旺弱判定</div>
                        <div class="txt ml-15">
                            同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
                        </div>
                        <div class="txt ml-15">经准确分析，该八字的喜用如下：</div>
                        <div class="tit1 ml-15">喜用五行</div>
                        <div class="txt ml-15 xjwx">
                            喜用五行:
                            <em
                            :class="enums[key]"
                            v-for="(value, key) in orderinfo.askTao.asktao_result['喜用']"
                            :key="key"
                            >{{ key }}</em
                            >
                        </div>
                        <div class="txt ml-15 xjwx">
                            忌用五行：
                            <em
                            :class="enums[key]"
                            v-for="(value, key) in orderinfo.askTao.asktao_result['忌用']"
                            :key="key"
                            >{{ key }}</em
                            >
                        </div>
                        <!-- 五行结束 -->
                        <div class="txt ml-15">
                            根据上述分析过程，已详细为您推算出您的五行喜用，后天通过补足、增旺五行，成就一生幸福。
                        </div>
                        <div class="txt ml-15">
                            综合该八字五行喜用分析和五行平衡，找另一半或者找朋友时，选择五行
                            <span
                            v-for="(value, key, index) in orderinfo.askTao.asktao_result['喜用']"
                            :key="index"
                            class="color-red"
                            >【{{ key }}】</span
                            >
                            旺的，有利于感情稳定，幸福长久。
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import resultData from "../../../mixin/homeMarriageTest/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .information{
        .content_box_{
            width: 7.18rem;
            border: .03rem solid #DC5744;
            opacity: 0.7;
            border-radius: .30rem;
            padding-bottom: .6rem;
            margin: 0 auto;
            margin-bottom: .48rem;
            .title_{
                width: 100%;
                height: .78rem;
                line-height: .78rem;
                font-size: .36rem;
                color: #C91C18;
                text-align: center;
                font-weight: bold;
                background: #fae7e5;
                margin-top: .25rem;
            }
            .content_name_text_box_{ //您的信息
                width: 100%;
                padding-left: .69rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .27rem;
                }
                .name_{
                    margin-top: .35rem;
                }
                .span_style__1{
                    .text__{
                        letter-spacing:2em
                    }
                }
            }
            .content_text_box_{
                padding: 0 .35rem;
                p{
                    font-size: .28rem;
                    color: #525252;
                    margin-top: .35rem;
                }
                //八字盘
            }
            .horoscope_box{
                width: 7.08rem;
                margin: 0 auto;
                border-radius: .3rem;
                text-align: center;
                background: #fff;
                // margin-bottom: 1rem;
                padding-top: .03rem;
                // padding-bottom: .5rem;
                margin-top: .36rem;
                .eight_layout{
                    text-align: left;
                    .text{
                        text-align: center;
                        font-size: .35rem;
                        margin: .3rem 0;
                    }
                    .center{
                        text-align: center;
                    }
                    .tit1 {
                        font-size: .3rem;
                        font-weight: bold;
                        color: #020202;
                        line-height: 1.8em;
                    }
                    .ml-15 {
                        margin-left: .25rem;
                    }

                    .txt {
                        font-size: .3rem;
                        font-weight: 400;
                        color: #020202;
                        line-height: 2em;
                        margin: .06rem .25rem;
                        margin-left: .25rem;
                    }
                    .wchl{
                        margin-left: .4rem;
                    }
                    .xjwx{
                        em {
                            border-radius: 50%;
                            width: .60rem;
                            height: .60rem;
                            color: white;
                            font-size: .4rem;
                            line-height: .6rem;
                            display: inline-block;
                            text-align: center;
                            margin-right: 3px;
                            font-style: normal;
                        }
                    } 
                }
            }
            
            
        }
    }
</style>